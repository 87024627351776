var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-logo-uploader"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("LOGO")]), _c('div', {
    staticClass: "logo-uploader"
  }, [_c('div', {
    class: ['logo-circle', _vm.isProfileType ? 'logo-circle--profile' : '', _vm.tempImageUrl ? 'logo-circle--filled' : '']
  }, [_vm.isProfileType ? _c('div', {
    staticClass: "logo-circle__avatar"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/person.svg"),
      "alt": "Avatar"
    }
  })]) : _c('img', {
    attrs: {
      "src": _vm.tempImageUrl
    }
  })]), _c('div', {
    staticClass: "upload-logo-container"
  }, [_c('GenproxButton', {
    attrs: {
      "outline": true
    },
    on: {
      "click": function click($event) {
        return _vm.openFileSelector();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "fileInput",
    attrs: {
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.addFile($event.target.files[0]);
      }
    }
  }), _vm.note ? _c('p', {
    staticClass: "upload-logo-note"
  }, [_vm._v(_vm._s(_vm.note))]) : _vm._e()], 1), _c('div', {
    staticClass: "hoverable-a-element",
    on: {
      "click": function click($event) {
        return _vm.deleteLogo();
      }
    }
  }, [_vm._v("Delete")])]), _c('sygni-input-error')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }